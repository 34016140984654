html, body {
    margin: 0;
    padding: 0;
    font-size: 16px;
}

#root {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
}

.container{
    width: 160px;
    height: 160px;
    display : grid;
    justify-items:stretch;
    gap : 1px;
    grid-template-columns: repeat(3, 1fr);
    padding: 0;
}

.container div {
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    background-color: #ccc;
}